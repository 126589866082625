<template>
  <nav :class="$vuetify.breakpoint.smAndDown ? 'sticky-nav' : 'py-8 py-md-12 navbar'">
    <v-container fluid class="px-5 px-md-9 px-lg-16">
      <v-row justify-lg="start" justify="start" align="center">
        <v-col cols="2" order="first">
          <img src="@/assets/img/aeon-logo-grey.png" alt="Aeon logo" width="100px" class="aeon-logo" />
        </v-col>

        <v-col cols="12" sm="10" md="8" lg="6" offset-lg="1" order="last" order-md="2" class="hidden-sm-and-down">
          <div class="d-flex flex-row flex-wrap justify-space-between align-center">
            <v-btn
              :to="`/${$i18n.locale}`"
              outlined
              elevation="0"
              color="#343768"
              class="pa-2 navbar-button--notactive navbar-button"
            >
              <span class="text-body-sm mb-0 text--aeon-navy">{{ $t('navbar.button-1') }}</span>
            </v-btn>

            <hr class="navbar-line" />
            <v-btn
              :to="`/${$i18n.locale}/climate-viz`"
              outlined
              elevation="0"
              color="#343768"
              class="pa-2 navbar-button"
              active-class="navbar-button--active"
            >
              <span class="text-body-sm mb-0 text--aeon-navy">{{ $t('navbar.button-2') }}</span>
            </v-btn>

            <hr class="navbar-line" />

            <v-btn
              :to="`/${$i18n.locale}/charts`"
              outlined
              elevation="0"
              color="#343768"
              class="pa-2 navbar-button"
              active-class="navbar-button--active"
            >
              <span class="text-body-sm mb-0 text--aeon-navy">{{ $t('navbar.button-3') }}</span>
            </v-btn>
          </div>
        </v-col>

        <UiLanguageToggle />

        <v-col cols="1" order="last" v-if="$vuetify.breakpoint.smAndDown">
          <v-btn @click="mobileMenu = !mobileMenu" width="100%" icon>
            <v-icon color="#343768" style="font-size: 45px">mdi-menu</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="$vuetify.breakpoint.smAndDown">
      <transition name="slide-left-centered">
        <div v-show="mobileMenu" class="px-5 py-12 navbar--mobile-menu">
          <div class="d-flex flex-row justify-space-between align-start">
            <a href="https://aeoncollective.org/" target="_blank">
              <img src="@/assets/img/aeon-logo-grey.png" alt="Aeon logo" width="100px" />
            </a>

            <v-btn @click="mobileMenu = !mobileMenu" icon>
              <v-icon color="#343768" style="font-size: 45px">mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="d-flex flex-column justify-center align-center menu">
            <v-btn
              :to="`/${$i18n.locale}`"
              outlined
              elevation="0"
              color="#343768"
              class="pa-2 navbar-button--notactive navbar-button"
            >
              <span class="text-body text--aeon-navy">{{ $t('navbar.button-1') }}</span>
            </v-btn>

            <hr class="navbar-line" />

            <v-btn
              @click="mobileMenu = false"
              :to="`/${$i18n.locale}/climate-viz`"
              outlined
              elevation="0"
              color="#343768"
              class="pa-2 navbar-button"
              active-class="navbar-button--active"
            >
              <span class="text-body text--aeon-navy">{{ $t('navbar.button-2') }}</span>
            </v-btn>

            <hr class="navbar-line" />

            <v-btn
              @click="mobileMenu = false"
              :to="`/${$i18n.locale}/charts`"
              outlined
              elevation="0"
              color="#343768"
              class="pa-2 navbar-button"
              active-class="navbar-button--active"
            >
              <span class="text-body text--aeon-navy">{{ $t('navbar.button-3') }}</span>
            </v-btn>
          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
import UiLanguageToggle from '@/components/ui/UiLanguageToggle';

export default {
  name: 'UiNavbar',
  components: {
    UiLanguageToggle,
  },
  data() {
    return {
      mobileMenu: false,
    };
  },
};
</script>

<style scoped lang="scss">
.navbar {
  img {
    display: flex;
  }  

  &-line {
    max-width: 96px;
    min-width: 24px;
    border: none;
    border-top: 1px dashed #343768;
  }

  &-button.v-btn--outlined {
    border: none;
  }

  &-button--active.v-btn--outlined {
    span {
      color: #a04031;
    }
  }

  &-button--active.v-btn--outlined.theme--light.v-btn--active::before,
  &-button--notactive.v-btn--outlined.theme--light.v-btn--active::before {
    background: none;
  }

  &--mobile-menu {
    background: linear-gradient(180deg, #aad4e7 0%, rgba(170, 212, 231, 1) 0.01%, #f9ece8 100%);
    position: absolute;
    max-height: 100vh;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .menu {
      height: 100%;
      .v-btn {
        width: 200px;
      }
    }

    .navbar-line {
      height: 48px;
      border: unset;
      width: 1px;
      min-width: unset;
      max-width: unset;
      border-left: 1px dashed #343768;
    }
  }
}

.slide-left-centered-enter,
.slide-left-centered-leave-to {
  transform: translateX(200%);
  // opacity: 0;
}

.slide-left-centered-enter-active {
  transition: all 0.4s ease;
}

.slide-left-centered-leave-active {
  transition: all 0.4s ease;
}

.sticky-nav {
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  z-index: 5; 
  background-color: rgba(245,249,254,0.75);
  img.aeon-logo {
    width: 72px !important;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .sticky-nav {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(245,249,254,0.75);
  }
}  


</style>
