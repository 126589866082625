<template>
  <v-menu bottom :left="alignProp" close-on-click offset-y nudge-bottom="8px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined elevation="0" color="#E27259" class="dropdown-button mr-4 mb-3" v-bind="attrs" v-on="on">
        <span class="text-body-sm mb-0">{{ menuLabel }}</span>
        <v-icon color="#E27259" style="font-size: 14px">{{ 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </template>

    <div class="dropdown-menu dirchange">
      <ul class="list">
        <li v-if="itemLabel" class="text-body-xs text--aeon-brown font-weight-medium">{{ $t(itemLabel[0]) }}</li>
        <li
          v-for="(item, index) in itemList1"
          :key="index + `-${menuLabel}`"
          class="text-body-xs text--aeon-brown font-weight-regular mb-3"
          @click="handleDataChange(item)"
        >
          {{ $t(item.label) }}
        </li>
      </ul>

      <hr class="my-1" v-if="itemList2" />

      <ul class="list" v-if="itemList2">
        <li v-if="itemLabel" class="text-body-xs text--aeon-brown font-weight-medium">{{ $t(itemLabel[1]) }}</li>
        <li
          v-for="(item, index) in itemList2"
          :key="index + `-${menuLabel}`"
          class="text-body-xs text--aeon-brown font-weight-regular mb-3"
          @click="handleDataChange(item)"
        >
          {{ $t(item.label) }}
        </li>
      </ul>

      <hr class="my-1" v-if="itemList3" />

      <ul class="list" v-if="itemList3">
        <li v-if="itemLabel" class="text-body-xs text--aeon-brown font-weight-medium">{{ $t(itemLabel[2]) }}</li>
        <li
          v-for="(item, index) in itemList3"
          :key="index + `-${menuLabel}`"
          class="text-body-xs text--aeon-brown font-weight-regular mb-3"
          @click="handleDataChange(item)"
        >
          {{ $t(item.label) }}
        </li>
      </ul>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'UiDropdownBtn',
  props: {
    itemList1: {
      type: Array,
      required: true,
    },
    itemList2: {
      type: Array,
      required: false,
    },
    itemList3: {
      type: Array,
      required: false,
    },
    itemLabel: {
      type: Array,
      required: false,
    },
    menuLabel: {
      type: String,
      required: true,
    },
    alignProp: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDataChange(item) {
      this.$emit('update-data', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.dropdown-button {
  padding: 12px 16px !important;
  // // width: 140px;
  // width: 200px;
  // max-width: 160px;
  text-transform: unset !important;
}

.dropdown-menu {
  padding: 4px 16px 4px 16px;
  background: #ffffff;
  max-height: 800px;
  overflow-y: scroll;
  z-index: 10;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  hr {
    background-color: transparent;
    border-top: 1px dashed #ac584b;
  }

  .list {
    width: 100%;
    margin: 0;
    padding: 4px 0;
    list-style-type: none;
    transform-origin: top;

    li {
      margin-bottom: 8px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #df644b;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
