<template>
  <div :class="['aeon-socials', center]">
    <p class="text-body-sm mb-4 mb-md-0 mr-md-6" :style="{ color: inputColor }">{{ $t('ui.btn-socials') }}</p>
    <span v-for="(icon, i) in socials" :key="i" :class="`mr-${iconSpacing}`">
      <a :href="icon.link" target="_blank">
        <v-icon :color="inputColor" :style="`font-size: ${size}px`">{{ icon.item }}</v-icon>
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'UiSocials',
  props: {
    inputColor: {
      type: String,
      default: '#343768',
    },
    iconSpacing: {
      type: Number,
      default: 8,
    },
    size: {
      type: Number,
      default: 32,
    },
    center: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      socials: [
        { item: 'mdi-web', link: 'https://aeoncollective.org/' },
        { item: 'mdi-twitter', link: 'https://twitter.com/aeonstrategy' },
        {
          item: 'mdi-instagram',
          link: 'https://www.instagram.com/aeonstrategy/',
        },
        {
          item: 'mdi-facebook',
          link: 'https://www.facebook.com/aeonstrategy/',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.aeon-socials {
  p {
    display: inline-block;
  }
  display: block;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    p {
      display: block;
    }
    margin: 0 auto;
  }

  a {
    border: none !important;
    text-decoration: none;
  }
}
</style>
