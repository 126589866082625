<template>
  <section class="concept-methodology dirchange">
    <v-container fluid class="px-5 px-md-9 px-lg-16">
      <v-row justify="center">
        <v-col cols="12" md="8" class="text--aeon-navy">
          <h3 class="text-subtitle font-weight-medium mb-8">{{ $t('method.header') }}</h3>
          <p class="text-body mb-4" v-for="(n, i) in $t('method.content')" :key="i" v-html="n.para" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'SectionMethods',
};
</script>

<style scoped lang="scss">
.concept-methodology {
  padding: 96px 0;
  background: linear-gradient(180deg, #f6faff 0%, rgba(244, 222, 213, 0.75) 100%);

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 48px 4px;
  }
}
</style>
