<template>
  <footer class="concept-footer">
    <v-container fluid class="px-sm-8 py-0 px-md-16">
      <v-row no-gutters justify="space-between" align="center">
        <!-- Viz + UI Ctrl -->
        <v-col cols="12" md="4" class="text-center text-md-right mb-8 mb-md-0" order-md="2">
          <p class="text-body-xs white--text mb-4">{{ $t('footer.asideRight') }}</p>
          <a href="https://studio.kontinentalist.com" target="_blank"
            ><img src="@/assets/img/studio-logo-white.svg" alt="kontinentalist logo" width="200px" class="konti-logo"
          /></a>
        </v-col>

        <v-col cols="12" md="2" class="text-center text-md-left" order-md="1">
          <a href="https://aeoncollective.org/" target="_blank"
            ><img src="@/assets/img/aeon-logo-white.png" alt="Aeon logo" width="185px" class="mb-4 mb-md-2"
          /></a>
          <p class="text-body-xs white--text mb-0">{{ $t('footer.asideLeft') }}</p>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: 'SectionFooter',
};
</script>

<style scoped lang="scss">
.concept-footer {
  padding: 48px 0;
  background: #bca59a;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 48px 4px 56px;
  }
}
</style>
